<template>
  <ul v-if="checkForActiveChannelMenu()"
      class="bx-header__channel-navi bx-typo--header-channel-navi">
    <li :class="getStyleClasses({ isActive: true })">
      <a :href="getAbsoluteUrl('/')">
        « &nbsp; Zurück zu Liebenswert
      </a>
    </li>
  </ul>
</template>

<script>
export default {
  props: {
    menuChannels: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      isChannelMenuActive: false
    }
  },
  methods: {
    checkForActiveChannelMenu () {
      this.menuChannels.forEach((channel) => {
        if (channel.isActive && channel.isActive === true) {
          this.isChannelMenuActive = true
        }
      })
      return this.isChannelMenuActive
    },
    getStyleClasses (channel) {
      return 'bx-header__channel-navi-item' + (channel.isActive && channel.isActive === true ? ' bx-js-active' : '')
    },
    getAbsoluteUrl (url) {
      if (import.meta.env.SSR) return url
      const a = document.createElement('a')
      a.href = url

      return a.href
    }
  }
}
</script>
