<template>
  <div class="bx-flooding bx-flooding--footer">
    <footer class="bx-footer bx-typo--footer">
      <div class="bx-footer__wrapper">
        <div class="bx-grid">
          <NewsletterSubscription usage="footer"
                                  headline="Abonnieren Sie unseren Newsletter:" />
          <SeoHeadline />
          <SocialLinks menu-type="footer" />
        </div>
      </div>

      <div class="bx-footer__menu">
        <div class="bx-grid">
          <MenuList :menu="footerData.menu"
                    menu-type="footer" />
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import MenuList from '../../../components/menu/MenuList.vue'
import SeoHeadline from '../../../components/page-footer/SeoHeadline.vue'
import NewsletterSubscription from '../../../components/shared/NewsletterSubscription.vue'
import SocialLinks from '../../../components/shared/SocialLinks.vue'

export default {
  components: {
    MenuList,
    SeoHeadline,
    SocialLinks,
    NewsletterSubscription
  },
  props: {
    footerData: {
      type: Object,
      default: () => { return {} }
    }
  }
}

</script>
